import React from 'react';
import Main from '../components/pages/Main';
import SwitchAccountant from '../components/pages/SwitchAccountant';

const TrocarContador = () => (
  <Main page="trocar-contador">
    <SwitchAccountant />
  </Main>
);

export default TrocarContador;
